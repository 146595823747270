import React from "react";
import "../Styles/Introduction.css";

const Introduction = () => {
  return (
    <>
      <div className="divIntroduction">
        <h1 className="introHeading"> WELCOME TO K-GREAT ENGINEERING</h1>
        <div className="divIntroText">
          <p className="introText">
            K-Great Engineering is a progressive Engineering outfit with a team
            of focused and goal oriented professionals that provide high quality
            mechanical and electrical consultancy and MEP services for both the
            domestic and commercial sectors. We are committed to providing
            imaginative, cost effective, long lasting and innovative designs and
            solutions to meet our client's individual requirements. <br />
            <br />
            We execute our projects with the highest professional standard with
            clear communication throughout the course of our projects. Over the
            last 10 years, we have built a reputation of delivering exceptional
            designs and bringing our client's desires to reality. We pursue to
            outdo ourselves on every new project.
            <br /> <br />
            K-Great Engineering is a company where innovation, craftsmanship,
            and excellence converge to redefine the world of mechanical and
            electrical construction. With a proud legacy of delivering
            exceptional construction solutions,K-Great Engineering stands as a
            paragon of industry leadership. At K-Great Engineering, we are more
            than just engineers; we are architects of possibility. Our journey
            in the construction industry has been marked by a relentless pursuit
            of excellence. Established years ago, our company has grown to
            become a trusted name in the field, catering to a diverse range of
            clients and projects. <br /> <br />
            K-Great Engineering specializes in providing a comprehensive suite
            of mechanical and electrical construction services. From designing
            and installing complex HVAC systems to creating energy-efficient
            electrical grids, we are your one-stop solution for all your
            construction needs. Our portfolio includes industrial facilities,
            commercial buildings, residential complexes, and more. <br /> <br />
            What truly sets K-Great Engineering apart is our unwavering
            commitment to excellence. We believe that every project, regardless
            of its scale, deserves the utmost attention to detail. Our team of
            experienced engineers, skilled technicians, and dedicated craftsmen
            ensures that every aspect of your project is meticulously planned
            and executed. Innovation as a Cornerstone: In a rapidly evolving
            industry, innovation is key. K-Great Engineering prides itself on
            staying at the forefront of technological advancements. We integrate
            cutting-edge solutions and sustainable practices into our projects,
            ensuring that our clients receive the most efficient and
            future-proof systems available.
          </p>
        </div>
      </div>
    </>
  );
};

export default Introduction;
