export const NavbarLinks = [
  {
    title: "Home",
    url: "/",
    cName: "navLinks",
  },
  {
    title: "About",
    url: "/About",
    cName: "navLinks",
  },
  {
    title: "Projects",
    url: "/Projects",
    cName: "navLinks",
  },
  {
    title: "Contact",
    url: "/Contact",
    cName: "navLinksContact",
  },
];
